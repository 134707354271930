<template>
  <div>
    <vue-element-loading :active="loading" :is-full-screen="true" />
    <v-card class="mx-auto" max-width="500">
      <v-card-title>
        <span class="me-3">{{ isEditing ? 'Edit Meal' : 'Create Meal' }}</span>
        <v-spacer></v-spacer>
        <v-btn depressed color="secondary" @click="$router.push({ name: 'meals' })" class="mx-1">
          Cancel
          <v-icon right dark>mdi-keyboard-return</v-icon>
        </v-btn>
        <v-btn depressed color="primary" @click="submitForm" class="mx-1">
          {{ isEditing ? 'Update' : 'Create' }}
          <v-icon right dark>mdi-content-save</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitForm">
          <v-text-field
            v-model="meal.name"
            label="Name"
            required
          ></v-text-field>
          <v-textarea
            v-model="meal.description"
            label="Description"
            required
          ></v-textarea>
          <v-text-field
            v-model="meal.price"
            label="Price"
            type="number"
            required
          ></v-text-field>

          <v-file-input
            label="Upload Meal Image"
            accept="image/*"
            @change="handleFileChange"
            prepend-icon="mdi-camera"
            outlined
          ></v-file-input>

          <v-switch
            v-model="meal.available"
            label="Available"
          ></v-switch>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueElementLoading from 'vue-element-loading';
import mealService from '@/services/MealService';

export default {
  components: {
    VueElementLoading,
  },
  data() {
    return {
      meal: {
        name: '',
        description: '',
        price: null,
        available: true,
        imageFile: null, 
      },
      isEditing: false,
      loading: false,
      error: null,
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.isEditing = true;
      this.loading = true;
      try {
        const response = await mealService.getMeal(this.$route.params.id);
        this.meal = response.data;
      } catch (err) {
        this.error = 'Error fetching meal.';
      } finally {
        this.loading = false;
      }
    }
  },
  methods: {
    handleFileChange(file) {
      this.meal.imageFile = file; // Correctly assign to this.meal.imageFile
    },
    async submitForm() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append('name', this.meal.name);
        formData.append('description', this.meal.description);
        formData.append('price', this.meal.price);
        formData.append('available', this.meal.available ? '1' : '0'); // Ensure boolean is passed correctly
        if (this.meal.imageFile) {
          formData.append('image', this.meal.imageFile);
        }

        if (this.isEditing) {
          await mealService.updateMeal(this.$route.params.id, formData);
        } else {
          await mealService.createMeal(formData);
        }

        this.$router.push({ name: 'meals' });
      } catch (err) {
        this.error = 'Error submitting form.';
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
}

.btn {
  margin-top: 10px;
}
</style>
